<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Mill </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="mill"
                                    :items="mills"
                                    default="" item-value="mill_id" item-text="mill_name"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Customer Group</span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="custGroup"
                                    :items="custGroups"
                                    default="" item-value="cust_id" item-text="cust_name"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <!-- <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Customer </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="cust"
                                    :items="custs"
                                    default="" item-value="cust_id" :item-text="item => item.cust_id +' - '+ item.cust_name.trim()"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col> -->

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Thick </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="thick"
                                    :items="thicks"
                                    default="" item-value="thick" item-text="thick"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Width </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="width"
                                    :items="widths"
                                    default="" item-value="width" item-text="width"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">AZ </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="coat_mass"
                                    :items="coat_masses"
                                    default="" item-value="coat_mass" item-text="coat_mass"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Grade </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="grade_id"
                                    :items="grade_ids"
                                    default="" item-value="grade_id" item-text="grade_id"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Color </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    clearable
                                    v-model="color_id"
                                    :items="color_ids"
                                    default="" item-value="color_id" item-text="descr"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <span class="blue-lcd mb-2 font-12">Period From</span>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <span class="blue-lcd mb-2 font-12">Period To</span>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="period_to" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col class="col-6" sm="6" md="2">
                                
                                <v-btn class="mt-4 border-12" style="padding: 19px;" color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    
                                    fixed-header
                                    height="500"
                                    :divider="true"
                                    :light="true"
                                >        
                                    <template v-slot:[`item.${header[0].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{ item.order_period }}</div>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.${header[1].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{ item.group_cust }}</div>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.${header[2].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.wgt_ord ? item.wgt_ord : 0)}}</div>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.${header[3].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.seven ? item.seven : 0)}}</div>
                                            <div>{{$store.getters.convertToCurrencyUs(item.seven_percent ? item.seven_percent : 0)}} %</div>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.${header[4].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.fourteen ? item.fourteen : 0)}}</div>
                                            <div>{{$store.getters.convertToCurrencyUs(item.fourteen_percent ? item.fourteen_percent : 0)}} %</div>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.${header[5].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.twentyone ? item.twentyone : 0)}}</div>
                                            <div>{{$store.getters.convertToCurrencyUs(item.twentyone_percent ? item.twentyone_percent : 0)}} %</div>
                                        </div>
                                    </template>

                                    <template v-slot:[`item.${header[6].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.twentytwo ? item.twentytwo : 0)}}</div>
                                            <div>{{$store.getters.convertToCurrencyUs(item.twentytwo_percent ? item.twentytwo_percent : 0)}} %</div>
                                        </div>
                                    </template>
                                    <template v-slot:[`item.${header[7].value}`]="{ item }">
                                        <div class="d-flex flex-column">
                                            <div>{{$store.getters.convertToCurrencyUs(item.total ? item.total : 0)}}</div>
                                            <div>{{$store.getters.convertToCurrencyUs(item.total_percent ? item.total_percent : 0)}} %</div>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default{
    data(){
        return{
            tzoffsetConvert : new Date().getTimezoneOffset() * 60000,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Delivery',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Lead Time Analysis',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            mill:'',
            mills:[],
            custGroup:'',
            custGroups:[
                {cust_id: 'GBRK', 
                cust_name:'Gerai Baja Ringan Kepuh'},
                {cust_id: 'KKA', 
                cust_name:'Kepuh Kencana Arum'},
                {cust_id: 'SM', 
                cust_name:'Sales & Marketing'},
            ],
            cust:'',
            custs:[],
            thick:'',
            thicks:[],
            width:'',
            widths:[],
            coat_mass:'',
            coat_masses:[],
            grade_id:'',
            grade_ids:[],
            color_id:'',
            color_ids:[],
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            header:[
                { text: 'Periode', value: 'order_period'},
                { text: 'Group Customer', value: 'group_cust'},
                { text: 'Contract', value: 'wgt_ord' , align:'right'},
                { text: '0-7 Days', value: 'seven' , align:'right', sortable: false},
                { text: '8-14 Days', value: 'fourteen' , align:'right', sortable: false},
                { text: '15-21 Days', value: 'twentyone' , align:'right', sortable: false},
                { text: '> 21 Days', value: 'twentytwo' , align:'right', sortable: false},
                { text: 'Total', value: 'total' , align:'right', sortable: false}
            ],
            data:[],
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true);
        this.getMill()
        this.getCustomer()
        this.getThick()
        this.getWidth()
        this.getCoatMass()
        this.getGrade()
        this.getColor()
        this.$store.dispatch('setOverlay', false);
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async getMill(){
            let reqBody = {
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-mill`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.mills = res.data.data
            }
        },
        async getCustomer(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-customer`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.custs = res.data.data
            }
        },
        async getThick(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-thick`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.thicks = res.data.data
            }
        },
        async getWidth(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-width`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.widths = res.data.data
            }
        },
        async getCoatMass(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-coatmass`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.coat_masses = res.data.data
            }
        },
        async getGrade(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-grade`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.grade_ids = res.data.data
            }
        },
        async getColor(){
            let reqBody = {
                "mill_id" : this.mill,
                "active_flag" : 'Y',
            }
            const res = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis/filter-color`, reqBody , { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            if (res.status === 200) {
                this.color_ids = res.data.data
            }
        },
        async getData(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true);
                var periodStart = ''
                var periodEnd = ''
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
                let reqBody = {
                    "mill_id" : this.mill,
                    "cust_group" : this.custGroup,
                    "cust_id" : this.cust,
                    "thick" : this.thick,
                    "width" : this.width,
                    "coat_mass" : this.coat_mass,
                    "grade_id" : this.grade_id,
                    "color_id" : this.color_id,
                    "period_start" : periodStart,
                    "period_end" : periodEnd
                }
                const respData = await axios.post(`${process.env.VUE_APP_URL}/api/v3/sr/delivery/lead-time-analysis`, reqBody , { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                if (respData.status === 200) {
                    this.data = respData.data.data
                    console.log(this.data)
                    this.$store.dispatch('setOverlay', false);
                    
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    this.$store.dispatch('setOverlay', false)
                    return false
                }
            }
        },
    },
}
</script>

<style>
    .tr_datatable{
        background-color: #F5F7F8 !important;
    }
</style>